import styled from "styled-components";

export const Abo = styled.div`
.items{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
iframe{
    height: 100vh;
    width: 40vw;
    margin: 20px;
}
@media (max-width: 1000px) {

    .items{
        flex-direction: row;
    }
    iframe{
        width: 90vh;
    }
}
`