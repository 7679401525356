import React from 'react'
import { Con } from './contact.styled'


export default function Contact() {

  document.querySelector('title').innerText = 'Contact | Blunyle'
  return (
    <div>
      <Con>
      <h1>Ask any question or enquiry you by filling in the following form</h1>
      <div className="form">
        <div> 
          <label htmlFor="">Firstname</label><br />
          <input placeholder='Francis' type="text" />
        </div>
        <div>
          <label htmlFor="">Lastname</label><br />
          <input placeholder='Ratalane' type="text" />
        </div>
        <div>
          <label htmlFor="">Email</label><br />
          <input placeholder='francisratalane@gmail.com' type="email" />
        </div>
        <div> 
          <label htmlFor="">Phone</label><br />
          <input placeholder='+27678525100' type="tel" />
        </div>
        <div> 
          <label htmlFor="">Message</label><br />
          <textarea name="" id="" cols="30" rows="10"></textarea>
        </div>
        <div className="submit">
          <button>Submit</button>
        </div>
      </div>
      </Con>
    </div>
  )
}
