import React from 'react'
import { Abo } from './about.styled'


export default function About() {

  document.querySelector('title').innerText = 'ShowCase | Blunyle'
  
  return (
    <div>
      <Abo>
        <div className="items">
          <iframe src="https://gym.blunyle.com" title='gym' frameBorder="0"></iframe>
          <iframe src="https://dentist.blunyle.com" title='ym' frameBorder="0"></iframe>
        </div>
      </Abo>
    </div>
  )
}
