import './App.css';
import React from 'react';
import { NavLink, Outlet, Link } from 'react-router-dom';
import logo from './Pages/assets/update.svg'
import phone from './Pages/assets/phone.svg'


function App() {

  
  
  return (
    <div className="App">
      
      <header className="App-header">
       <div className="logo">
        
        <Link to='/' className="text-logo"> 
          <img  src={logo} alt="" />
        </Link>
       </div>
       <div className="contact-links">
       <img height={32} src={phone} alt=''/><a href="tel:+27678525100"> 067 852 5100</a>
       </div>
       <div className="header-links">
        <NavLink to='' className='link'>Home</NavLink>
        <NavLink to='packages' className='link'>Packages</NavLink>
        <NavLink to='showcase' className='link'>Showcase</NavLink>
        <NavLink to='contact' className='link'>Contact</NavLink>
       </div>
      </header>
      
      <div className="App-content">
        
        <Outlet/>
      </div>
      <footer className="App-footer">
        <div className="copyright">
        © Copyright Blunyle 2022 
        </div>
        <a href="mailto:francisratalane@gmail.com">francisratalane@gmail.com</a>
      </footer>
    </div>
  );
}

export default App;
