import React from 'react'
import { Sev } from './services.styled'
import vid from '../Pages/assets/hd.mp4'
import godaddy from '../Pages/assets/stack/godaddy.svg'
import firebase from '../Pages/assets/stack/firebase.svg'
import mongodb from '../Pages/assets/stack/mongodb.svg'
import reactjs from '../Pages/assets/stack/reactjs.svg'
import html5 from '../Pages/assets/stack/html5.svg'
import css3 from '../Pages/assets/stack/css3.svg'
import { Link } from 'react-router-dom'

export default function Services() {

  document.querySelector('title').innerText = 'Packages | Blunyle'
  return (
    <div>
      <Sev>
        <div className="intro">
          <div className="back">
            <div className="abs">
              <h1>We help our clients based on the size of thier business,</h1><h1 className='diff'>
              You can upgrade to any package at any time  
              </h1>
            </div>
            <video autoPlay loop src={vid}></video>
          </div>
        </div>
        <h2>Choose a package suitable for you business to get started</h2>
        <div className="packages">
          <div className="card">
            <div className="card-head">
               <h3>Small</h3>
            </div>
            <div className="card-body">
              <ul>
                <li style={{listStyle: 'none'}}><h3>R3500 <br />Then R150 p/m</h3></li>
                <li>Up to 4 Pages</li>
                <li>SSL Certificate</li>
                <li>30min Content Update p/m</li>
                <li>Adapts To All Devices & Screen-Sizes</li>
              </ul>
            </div>
          </div>
          <div className="card">
            <div className="card-head">
               <h3>Medium</h3>
            </div>
            <div className="card-body">
              <ul>
                <li style={{listStyle: 'none'}}><h3>R4800 <br />Then R270 p/m</h3></li>
                <li>Up To 10 Pages</li>
                <li>SSL Certificate</li>
                <li>Maitanance</li>
                <li>Gallery</li>
                <li>1hr Content Update p/m</li>
                <li>Custom Design</li>
                <li>Adapts To All Devices & Screen-Sizes</li>
              </ul>
            </div>
          </div>
          <div className="card">
            <div className="card-head">
               <h3>Large</h3>
            </div>
            <div className="card-body">
              <ul>
                <li style={{listStyle: 'none'}}><h3>R6500 <br />Then R470 p/m</h3></li>
                <li>Up to 20 Pages</li>
                <li>Admin Page</li>
                <li>SSL Certificate</li>
                <li>Maintainance</li>
                <li>Gallery</li>
                <li>2hr Content Update p/m</li>
                <li>Adapts To All Devices & Screen-Sizes</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="quote">
          <h1>Contact us for a custom Quote from R1500 <Link id='q-a' to='/contact'>Contact</Link> <br />and get and we will get back to you</h1>
          
        </div>
        <div className="stack">
          <ul>
            <li><picture><img src={godaddy} alt="godaddy" /></picture></li>
            <li><picture><img src={firebase} alt="firebase" /></picture></li>
            <li><picture><img src={mongodb} alt="mongodb" /></picture></li>
            <li><picture><img src={reactjs} alt="reactjs" /></picture></li>
            <li><picture><img src={html5} alt="html5" /></picture></li>
            <li><picture><img src={css3} alt="css3" /></picture></li>
          </ul>
        </div>
      </Sev>
    </div>
  )
}
