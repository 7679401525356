import React from 'react'
import { Hme } from './home.styled'
import img from '../Pages/assets/boardroom.png'
import body from '../Pages/assets/computer.jpg'
import { Link } from 'react-router-dom'


export default function Home() {
  
  document.querySelector('title').innerText = 'Blunyle - web design and development'
  return (
    <Hme>
      <div>
        <div className="intro">
        <div className="wrap">
          <div className="view">
            <picture><img src={img} alt="" /></picture><h1>Build Trust with Your Customers By Exposing Your Business and services Online.
            </h1>
          </div>
          <div className="actions">
            <Link about='' className='home-aa' to='/contact'>Get in touch</Link>
            <Link className='home-ab' to='/packages'>See Plans</Link>
          </div>
        </div>
        </div>
        <div className="body">
          <div className="b-wrap">
            <img src={body} alt="" />
            <h3>A website designed by us can have All the features below
              based on a plan you choose and the budget you have {'()=>{}'}.We will also
              walk you through the process of purchasing a domain( a domain is "yourbusinessname.co.za")
              for your business.
            </h3>
          </div>
          
        </div>
        <div className="card">
          <div className='card-wrap'>
             <div className="c-head">Website features</div>
             <div className="card-body">
              <ul>
                <li>
                  <h3>Mobile Responsive Friendly</h3><br />
                  <p>Websites will be adjusted and can be viewed on any mobile device, cellphone, tablet, ipad.</p>
                </li>
                <li>
                  <h3>Social Media Butoons</h3><br />
                  <p>If you have a facebook page, we can have a facebook icon on your site which will link to your fb page and the same for all your other social media profiles. Chat to us about getting a facebook page if you dont have one already.</p>
                </li>
                <li>
                  <h3>Contact Form</h3><br />
                  <p>A form that your users can fill in to request more information eg. the user's name, tel, email and their enquiry. This is a great method of building a database of clients to keep in contact with in the future.</p>
                </li>
                <li>
                  <h3>Image Gallery</h3><br />
                  <p>You can have a gallery of images, displayed with thumbnails and if the user clicks on it, it will popup in a large display. You can categorize your gallery as well and have as many images as you like.</p>
                </li>
                <li>
                  <h3>Youtube</h3><br />
                  <p>If you have a relevant video on youtube that you want you visitors to see we will embed it on your website.</p>
                </li>
                <li>
                  <h3>Google Maps</h3><br />
                  <p>Google Maps is great for your contact us page to show your users where you or your offices are located.</p>
                </li>
                <li>
                  <h3>Admin Page</h3><br />
                  <p>If you want your site to have an admin login page to manage your business any how we will create an admin page for you</p>
                </li>
              </ul>
             </div>
            </div>
          </div>
      </div>
    </Hme>
  )
}
