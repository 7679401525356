import styled from "styled-components";

export const Con = styled.div`

h1{
    color: blueviolet;
    text-align: center;
    margin: 15px;
}
.form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.form div{
    margin: 15px 0px;
}
label{
    font-weight: 500;
    color: #2f4f42;
}
input, textarea{
    border: 2px solid #06f;
    outline: none;
    width: 500px;
    padding: 10px;
    font-size: 1em;
    font-weight: 600;
    color: blueviolet;
}
textarea{
    resize: none;
}
input:focus, textarea:focus{
    transition-duration: 300ms;
    border: 2px solid blueviolet;

}
button{
    cursor: pointer;
    padding: 10px;
    width: 200px;
    border: none;
    font-size: 1.1em;
    font-weight: 500;
    background-color: blueviolet;
    color: aliceblue;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, .4);

}
@media (max-width: 610px) {
    input, textarea{
        width: 80vw;
    }
}

`