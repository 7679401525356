import styled from "styled-components";

export const Hme = styled.main`

h1{
    color: #fff;
}
.view{
    display: flex;
    /* justify-content: center; */
    width: 800px;
    padding: 15px;
    
}
.intro{
    background-color: #242530;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    padding-top: 60px;
    
}
.intro img{
  
   filter: brightness(80%);
   width: auto;
   height: 300px;
   border-radius: 5px;
   transition-property: opacity;
   transition-duration: 5000ms;
}
.actions{
    display: flex;
    justify-content: space-around;
}
.home-aa{
    text-decoration: none;
    background-color: #0066ff;
    color: #fff;
    font-weight: 500;
    font-size: 1.1em;
    padding: 10px 20px;
    border-radius: 50px;
}
.home-ab{
    text-decoration: none;
    background-color: transparent;
    color: #0066ff;
    border: 2px solid #0066ff;
    font-weight: 500;
    font-size: 1.1em;
    padding: 10px 20px;
    border-radius: 50px;
}
.body img{
   width: 550px;
   margin: 0px;
   padding: 10px;
}
.b-wrap{
    display: flex;
     
}
.b-wrap h3{
    align-self: center;
    color: #2f4f4f;
    margin: 0px;
    font-weight: 500;
}
.card{
    display: flex;
    justify-content: center;
    
}
.card .card-wrap{
    width: 600px;
    height: fit-content;
    border-radius: 5px;
    border: 1px solid #0066ff;
    box-shadow: 4px 4px 20px #00000023;
}
.card .c-head{
    padding: 15px 0px;
    text-align: center;
    color: #0066ff;
    font-size: 1.4em;
    font-weight: 500;
    border-bottom: 1px solid #0066ff;
}
.card .card-body{
    padding: 15px;
}


li{
    color: #ff0000;
    list-style: disc;
    margin: 25px 0px;
    line-height: 20px;
}
li h3{
    margin: 0px;
    color: #0066ff;
    font-weight: 500;
}
li p{
    margin: 0px;
    color: #0034ff;
    font-weight: 500;
}
@media (max-width: 800px) {
    .view{
      width: auto;
    }
    .body{
        padding: 10px;
    }
    .body img{
      width: 300px;
      margin: 0px;
      padding: 10px;
    }
    .card-wrap{
        width: auto;
    }
    .b-wrap{
        flex-wrap: wrap;
    }
    .card{
       margin: 10px;
    }
    .view{
        flex-wrap: wrap;
    }
    ul{
        padding-left: 10px;
    }
}

`