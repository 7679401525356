import styled from "styled-components";


export const Sev = styled.main`


.back{
    position: relative;
}
.abs{
    position: absolute;
}
h1{
    
    color: #fff;
}
.intro .back video{
    background-color: #242530;
    width: 100vw;
    
}
.diff{
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #d00;
}
h2{
    text-align: center;
    color: #242530;
}
.packages{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    
}
.card{
    margin: 15px;
    width: 300px;
    border-radius: 2px;
    
}
.card:nth-of-type(1){
    border: 2px solid #d00;
}
.card:nth-of-type(2){
    border: 2px solid #0a0;
}
.card:nth-of-type(3){
    border: 2px solid #06f;
}
.card-head h3{
    margin: 0px;
    color: #fff;
    text-align: center;
    padding: 5px;
}
.card:nth-of-type(1) .card-head{
    background-color: #d00;

}
.card:nth-of-type(2) .card-head{
    background-color: #0a0;

}
.card:nth-of-type(3) .card-head{
    background-color: #06f;

}
li{
    color: #2f4f49;
    font-weight: 500;
}
.stack ul{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.stack li{
    list-style: none;
    margin: 15px;
}
.stack li picture img{
    height: 50px;
}
.quote h1{
    color: #d00;
    text-align: center;
}

@media (min-width: 600px) {
    video{
        display: none;
    }
    .intro{
        background-color: #242530;
        display: flex;
        justify-content: center;
    }
    .abs{
        position: static;
        width: 600px;
    }
    h1{
        color: #fff;
    }
    .diff{
        color: transparent;
    }
}
@media (max-width: 500px) {
    .abs h1{
        margin: 0px;
        font-weight: 500;
    }
    .back{
        height: fit-content;
    }
}
`